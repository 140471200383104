export enum Gender {
  Male = "MALE",
  Female = "FEMALE",
  Unknown = "UNKNOWN",
}

export enum APPOINTMENT_STATUS {
  NEW = "NEW",
  ARRIVED = "ARRIVED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  NO_SHOW = "NO SHOW",
  CONFIRMED = "CONFIRMED",
  WAITING = "WAITING",
  FOLLOW_UP = "FOLLOW UP",
}
