import { DateTime, Interval } from "luxon";
import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { calendarAdapter } from "./calendarSlice";

const { selectById, selectAll } = calendarAdapter.getSelectors(
  (state: RootState) => state.calendar
);

export const selectCalendarCardById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectCalendarCardsByAppointmentId = (id: number | string) => (state: RootState) => {
  const cards = selectAll(state);

  return cards.filter((card) => card.appointmentId === id);
};

export const selectAllCalendarCards = selectAll;

export const selectCalendarCardsByDateRange =
  (startDate: DateTime, endDate: DateTime) => (state: RootState) => {
    const cards = selectAll(state);

    return cards.filter((card) => {
      const cardStart = DateTime.fromISO(card.start);
      return cardStart >= startDate && cardStart < endDate;
    });
  };
export const selectCalendarIsLoading = (state: RootState) =>
  state.calendar.status === SliceStatus.LOADING;

export const selectDoesTimeHaveConflict =
  (
    startTime?: DateTime,
    endTime?: DateTime,
    staff?: number,
    resource?: number,
    currentSlotId?: number
  ) =>
  (state: RootState) => {
    let staffSlotsThatConflict = [];
    let resourceSlotsThatConflict = [];
    const interval = startTime && endTime && Interval.fromDateTimes(startTime, endTime);
    let allArticleSlots = selectAll(state)
      .filter(
        (slot) =>
          slot.start &&
          slot.end &&
          slot.status !== "NO SHOW" &&
          slot.status !== "COMPLETED" &&
          slot.status !== "CANCELLED"
      )
      .filter(
        (slot) =>
          (interval && interval.contains(DateTime.fromISO(slot.start))) ||
          (interval && interval.contains(DateTime.fromISO(slot.end)))
      );

    // Exit if no times or both resource and staff don't exist yet
    if (!endTime || !startTime || (!staff && !resource))
      return {
        staff: false,
        resource: false,
      };

    //Exclude current slot from raising conflicts
    if (currentSlotId)
      allArticleSlots = allArticleSlots.filter((slot) => slot.id !== currentSlotId);

    if (staff) {
      staffSlotsThatConflict = allArticleSlots.filter((slot) => slot.staff === staff);
    }

    if (resource) {
      resourceSlotsThatConflict = allArticleSlots.filter((slot) => slot.resource === resource);
    }

    return {
      staff: Boolean(staffSlotsThatConflict.length),
      resource: Boolean(resourceSlotsThatConflict.length),
    };
  };
