import { Box, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { TableHeader } from "./TableTypes";

interface MobilePeepTableCardProps {
  header: React.ReactNode;
  actionMenu?: React.ReactNode;
  headers: TableHeader[];
  item: Record<string, React.ReactNode>;
  variants?: React.ReactNode;
}

export default function MobilePeepTableCard({
  headers,
  header,
  item,
  actionMenu,
  variants,
}: MobilePeepTableCardProps) {
  return (
    <Card
      sx={{
        boxShadow: (theme) => theme.shadows.card,
      }}
    >
      <CardContent>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {header}
          {actionMenu && <Box>{actionMenu}</Box>}
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          {headers.map((header: TableHeader) => {
            return (
              <React.Fragment key={header.key}>
                <Grid item xs={6}>
                  <Typography
                    textTransform="uppercase"
                    variant="primary"
                    color="#848FA1"
                    fontWeight="bold"
                  >
                    {header.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {item[header.key]}
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </CardContent>
      {variants}
    </Card>
  );
}
