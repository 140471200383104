import { SvgIconProps } from "@mui/material";
import {
  Arrived,
  Cancel,
  Completed,
  Confirmed,
  FollowUpIcon,
  New,
  NoShow,
  Started,
  Waiting,
} from "assets/icon";
import { AppointmentStatusOptions } from "types/Appointment";

export const getAppointmentStatusIcon = (
  value: AppointmentStatusOptions,
  fontSize: SvgIconProps["fontSize"] = "small"
) => {
  switch (value) {
    case AppointmentStatusOptions.Arrived:
      return <Arrived fontSize={fontSize} />;

    case AppointmentStatusOptions.NoShow:
      return <NoShow fontSize={fontSize} />;

    case AppointmentStatusOptions.Started:
      return <Started fontSize={fontSize} />;

    case AppointmentStatusOptions.Completed:
      return <Completed fontSize={fontSize} />;

    case AppointmentStatusOptions.Canceled:
      return <Cancel fontSize={fontSize} />;

    case AppointmentStatusOptions.Confirmed:
      return <Confirmed fontSize={fontSize} />;

    case AppointmentStatusOptions.Waiting:
      return <Waiting fontSize={fontSize} />;

    case AppointmentStatusOptions.FollowUp:
      return <FollowUpIcon fontSize={fontSize} />;

    default:
      return <New fontSize={fontSize} />;
  }
};
