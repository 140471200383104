import { Box } from "@mui/material";
import EmptyGraphicSection from "../EmptySectionGraphic";
import type { PeepTableProps } from "./PeepTable";
import { TableItem, TableRowData } from "./TableTypes";
import { Fragment } from "react";
import MobilePeepTableCard from "./MobilePeepTableCard";

export default function MobilePeepTable({
  isLoading,
  items,
  emptySectionTitle,
  emptySectionPadding,
  emptySectionWidth,
  headers,
  maxHeight,
}: PeepTableProps) {
  if (!isLoading && !items.length) {
    return (
      <EmptyGraphicSection
        description={emptySectionTitle}
        pb={emptySectionPadding}
        width={emptySectionWidth}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" maxHeight={maxHeight} gap={2} bgcolor="bg.secondary">
      {items.map((item: TableItem | TableRowData, index: number) => {
        const isCollapsibleItem = "cells" in item;
        const hasAction = isCollapsibleItem ? "action" in (item.cells as any) : "action" in item;

        const filteredHeaders = headers.filter((header) => !hasAction || header.key !== "action");

        return (
          <Fragment key={(item.id as number) || index}>
            {isCollapsibleItem ? (
              <MobilePeepTableCard
                header={(item.cells as any)[headers[0].key].component}
                actionMenu={hasAction ? (item.cells as any).action.component : undefined}
                headers={filteredHeaders.slice(1)}
                item={
                  Object.fromEntries(
                    Object.entries(item.cells).map(([key, value]) => [key, value.component])
                  ) as Record<string, React.ReactNode>
                }
                variants={item.isCollapsible ? (item as TableRowData).variants : undefined}
              />
            ) : (
              <MobilePeepTableCard
                header={item[headers[0].key].component}
                headers={filteredHeaders.slice(1)}
                actionMenu={hasAction ? item.action.component : undefined}
                item={
                  Object.fromEntries(
                    Object.entries(item).map(([key, value]) => [key, value.component])
                  ) as Record<string, React.ReactNode>
                }
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
}
